import GATSBY_COMPILED_MDX from "/home/runner/work/signatu/signatu/packages/docs/src/content/docs/api/v1/integration/webhooks.md";
import React from 'react';
import MdxStyleProvider from '../components/mdx-style-provider';
import mapToTemplate from '../layouts/map-to-layout';
const MdxDocTemplate = ({className, pageContext, children, ...restProps}) => {
  if (!pageContext) {
    return React.createElement("div", {
      className: className
    }, React.createElement(MdxStyleProvider, null, children));
  }
  const {frontmatter} = pageContext;
  const {layout} = frontmatter;
  const LayoutComponent = mapToTemplate(pageContext.slug, layout);
  return React.createElement(LayoutComponent, Object.assign({
    className: className,
    frontmatter: frontmatter
  }, restProps), React.createElement(MdxStyleProvider, null, children));
};
MdxDocTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(MdxDocTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
